//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE =
  "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE =
  "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MESSAGE =
  "An error has occurred. The photo was unable to upload.";
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";

// export const apiUrl = "http://localhost:9291";
// export const imageUrl = "http://localhost:9291/images";
// export const apiMeetUrl = "http://localhost:9291/api";
// export const apiUrlRecord = "http://localhost:9291/record";

// export const apiUrl = "https://dev.dcm.co.th";
// export const imageUrl = "https://dev.dcm.co.th/images";
// export const apiMeetUrl = "https://dev.dcm.co.th/api";
// export const apiUrlRecord = "https://dev.dcm.co.th/record";

// export const apiUrl = "https://apidmeetsdev.dcm.co.th";
// export const imageUrl = "https://apidmeetsdev.dcm.co.th/images";
// export const apiMeetUrl = "https://apidmeetsdev.dcm.co.th/api";
// export const apiUrlRecord = "https://apidmeetsdev.dcm.co.th/record";

// export const apiUrl = "https://dmeet-backend-node.herokuapp.com";
// export const imageUrl = "https://dmeet-backend-node.herokuapp.com/images";
// export const apiMeetUrl = "https://dmeet-backend-node.herokuapp.com/api";
// export const apiUrlRecord = "https://dmeet-backend-node.herokuapp.com/record";

export const apiUrl = "https://apidmeets.dcm.co.th";
export const imageUrl = "https://apidmeets.dcm.co.th/images";
export const apiMeetUrl = "https://apidmeets.dcm.co.th/api";
export const apiUrlRecord = "https://apidmeets.dcm.co.th/record";

//export const apiMeetUrl = "https://dmeets-api.devson.club/api";

export const server = {
  LOGIN_URL: `account/login`,
  REGISTER_URL: `account/register`,
  FORGOT_URL: `account/forgot`,
  RESET_URL: `account/resetpassword`,
  ACCOUNT_URL: `account`,
  PRODUCT_URL: `product`,
  COMPANY_URL: `company`,
  APIKEY_URL: `apikey`,
  APPID_URL: `appid`,
  TRANSACTION_URL: `transaction`,
  REPORT_URL: `report`,
  TOKEN_KEY: `token`,
  USERNAME: `username`,
  USER_ID: `user_id`,
  FULLNAME: `fullname`,
  ROLE: `role`,
  COMPANYID: `company_id`,
  MEET_GET_JWT: `generate-token`,
  MEETROOM_URL: `meet`,
  MEETROOM_JOIN_URL: `meetjoin`,
  MEET_STA: `false`,
  ROOM: `rooms`,
  DEPARTMENT: `department`,
  COMPANY_NAME: `company_name`,
  DEPARTMENT_ID: `department_id`,
  DEPARTMENT_NAME: `department_name`,
  VOTE_URL: `vote`,
  PRIVACY_STA: `privacy`,
  PDPA_URL: `pdpa`,
  ACC_PLAN_STA: `acc_plan`,
  PACKAGE_URL: `package-configs`,
  MODERATOR_STA: `moderator`,
  VERSION_FREE: `1.7`,
  VERSION_PREMIUM: `1.0`,
  VERIFY: `verify`,
  LOG_HOST: `log-host`,
  LOG_PARTICIPANT: `log-participant`,
  RECORD_LINK_URL: `record-link`,
  POLL_VOTE: `poll_vote`,
};

export const feature = {
  FEATURE_LOBBY: `feature_lobby`,
  FEATURE_RECORD: `feature_record`,
  FEATURE_SUBTITLE: `feature_subtitle`,
  FEATURE_VOTE: `feature_vote`,
  FEATURE_VOTE_REPORT: `feature_vote_report`,
  FEATURE_MUTE_EVERYONE: `feature_mute-everyone`,
  FEATURE_MUTE_VIDEO_EVERYONE: `feature_mute-video-everyone`,
  LANGUAGE: `language`,
};
