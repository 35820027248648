export const lg_vote_pdf_dialog = {
  vote_pdf_dialog_th: {
    title: "รายงานผลโหวต",
    btn_download: "ดาวน์โหลด",
    btn_close: "ปิด",
    vote_topic:"หัวข้อโหวต: ",
    from_meeting:"ชื่อการประชุม: ",
    as_of:"วันที่: ",
    number_participants:"จำนวนผู้เข้าร่วม: ",
    people:"คน",
    answer:"คำตอบที่",
    vote_count:"จำนวนคะแนนที่นับได้: ",
    participant_count:"จำนวนผู้มีสิทธิ์ทั้งหมด: ",
    tbl_participant_name:"ชื่อผู้เข้าร่วม",
    tbl_vote_option:"ตัวเลือก",
    tbl_vote_options:"ตัวเลือก",
    number_of_vote:"จำนวนผู้ลงคะแนน",
    tbl_vote_count:"จำนวนผู้ลงคะแนน",
    participation_rate:"อัตราการเข้าร่วมลงคะแนน: "
  },
  vote_pdf_dialog_en: {
    title: "Vote Result",
    btn_download: "Download",
    btn_close: "Close",
    vote_topic:"Vote Question: ",
    from_meeting:"Meeting Name: ",
    as_of:"Date: ",
    number_participants:"Number of Participants: ",
    people:"people",
    answer:"Answer",
    vote_count:"Total Votes Counted: ",
    participant_count:"Total Participants: ",
    tbl_participant_name:"Participant Name",
    tbl_vote_option:"Vote Option",
    tbl_vote_options:"Vote Options",
    number_of_vote:"Number of Votes",
    tbl_vote_count:"Vote Count",
    participation_rate:"Participation Rate: "
  },
};
